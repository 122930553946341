import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../Shared/AppContext';
import { Header } from '../Header/Header';
import './Layout.css';
import { ServiceWorkerWrapper } from './ServiceWorkerWrapper';

export const Layout = props => {
  const { appContext } = useContext(AppContext);

  useEffect(() => {
    document.body.className = 'theme-' + appContext.theme;
  }, [appContext.theme]);

  return (
    <div className={'layout-container theme-' + appContext.theme}>
      <ServiceWorkerWrapper />
      <Header />
      <main className="p-0 pt-md-3 ps-md-3 pe-md-3">
        {props.children}
      </main>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { getAuth, signOut } from '@firebase/auth';
import { Timestamp } from '@firebase/firestore';
import { endAt, endBefore, equalTo, get, getDatabase, off, onValue, orderByChild, push, query, ref, remove, startAfter, startAt, update } from "firebase/database";
import React, { useEffect, useRef, useState } from 'react';
import { calculateHandicapPlay, getScoreCardStableford } from './Calculations';
import { playModes, scoringModes, TeeTypeGolf } from './Config';
import { getItemFromDatabaseSnapshot, getItemsFromDatabaseQuerySnapshot, getTimestampFromDate } from './Utils';
import { usePersistedState } from './usePersistedState';

export const AppContext = React.createContext({});

export const AppContextProvider = ({ children }) => {
    const [user, setUser] = useState();
    const [userData, setUserData] = useState();
    const [courses, setCourses] = useState();
    const [tournaments, setTournaments] = useState();
    const [users, setUsers] = useState();
    const [matches, setMatches] = useState();
    const [theme, setTheme] = usePersistedState('userProperties.theme', "light");

    // ---------------------------------------------------------------

    const logoff = async () => {
        return signOut(getAuth());
    }

    // ---------------------------------------------------------------

    const addScoreCard = async scoreCardInitData => {
        const date = Timestamp.fromDate(new Date());
        const baseScoreCard = {
            date: date,
            creationDate: date,
            modifiedDate: date,
            event: '',
            courseName: '',
            courseId: '',
            teeId: '',
            holeCount: 0,
            holes: [],
            shotCount: 0,
            stableford: 0,
            handicap: 0,
            handicapPlay: 0,
            handicapGolf: +appContext.userData.handicapGolf,
            handicapPitchAndPutt: +appContext.userData.handicapPitchAndPutt,
            isCompetition: false,
            notes: '',
            greenFee: 0,
            usedBuggy: false,
            userId: appContext.user.uid,
            creatorId: appContext.user.uid
        }
        let newScoreCard = { ...baseScoreCard, ...scoreCardInitData };

        let newDocRef = ref(getDatabase(), "scoreCards");
        const newDoc = await push(newDocRef, newScoreCard);
        newScoreCard.id = newDoc.key;
        return newScoreCard
    }

    const updateScoreCard = async theScoreCard => {
        const newScoreCard = {
            ...theScoreCard,
            ...{
                modifiedDate: Timestamp.fromDate(new Date()),
                date: Timestamp.fromDate(theScoreCard.date),
                duration: theScoreCard.duration || 0.0
            }
        };
        const url = 'scoreCards/' + newScoreCard.id;
        const newDoc = ref(getDatabase(), url);
        return await update(newDoc, newScoreCard);
    }

    const deleteScoreCard = async scoreCard => {
        const scoreCardDoc = ref(getDatabase(), 'scoreCards/' + scoreCard.id);
        await remove(scoreCardDoc);
    }

    const setHolesByCourseAndTee = (newScoreCard, courseId, teeId) => {
        const newCourse = appContext.getCourseById(courseId);
        const tee = newCourse?.tees.find(tee => {
            return (tee.id === teeId) || (tee.name === teeId);
        }) || newCourse?.tees[0];

        const scoreCardHoles = newScoreCard.holes?.sort((a, b) => a.number > b.number) || [];
        const teeHoles = tee?.holes.sort((a, b) => a.number > b.number);

        let newHoles = (tee && (scoreCardHoles.length >= tee?.holeCount)) ?
            (scoreCardHoles || []).filter(hole => hole.number <= tee?.holeCount)
            :
            [
                ...scoreCardHoles,
                ...Array((tee?.holeCount - scoreCardHoles.length) || 0).fill({
                    hcp: 0,
                    par: 0,
                    distance: 0,
                    extraShots: 0,
                    shotCount: 0,
                    puttCount: 0,
                    bunkerCount: 0,
                    fairway: 0,
                    gir: 0,
                    notes: '',
                    penaltyCount: 0,
                    clubs: [],
                    latitude: 0,
                    longitude: 0
                }).map((hole, index) => ({ ...hole, number: scoreCardHoles.length + index + 1 }))
            ];

        const newNewHoles = newHoles.map(hole => ({
            ...hole,
            hcp: teeHoles[hole.number - 1]?.hcp,
            par: teeHoles[hole.number - 1]?.par,
            distance: teeHoles[hole.number - 1]?.distance || 0,
            latitude: teeHoles[hole.number - 1]?.latitude || 0,
            longitude: teeHoles[hole.number - 1]?.longitude || 0,
            teeLatitude: teeHoles[hole.number - 1]?.teeLatitude || 0,
            teeLongitude: teeHoles[hole.number - 1]?.teeLongitude || 0,
            greenFrontLatitude: teeHoles[hole.number - 1]?.greenFrontLatitude || 0,
            greenFrontLongitude: teeHoles[hole.number - 1]?.greenFrontLongitude || 0,
            greenEndLatitude: teeHoles[hole.number - 1]?.greenEndLatitude || 0,
            greenEndLongitude: teeHoles[hole.number - 1]?.greenEndLongitude || 0,
            middleLatitude: teeHoles[hole.number - 1]?.middleLatitude || 0,
            middleLongitude: teeHoles[hole.number - 1]?.middleLongitude || 0,
            obstacles: teeHoles[hole.number - 1]?.obstacles || []
        }));

        const theScoreCard = {
            ...newScoreCard,
            courseId: courseId,
            teeName: tee?.name === undefined ? '' : tee?.name,
            teeId: tee?.id === undefined ? '' : tee?.id,
            holeCount: tee?.holeCount,
            holes: newNewHoles,
            handicap: (tee?.type === TeeTypeGolf ? newScoreCard.handicapGolf : newScoreCard.handicapPitchAndPutt) || 36,
        };
        theScoreCard.handicapPlay = theScoreCard.handicapPlay || calculateHandicapPlay(appContext, theScoreCard, tee);
        return calculateHCPByHole(theScoreCard);
    }

    const calculateHCPByHole = newScoreCard => {
        const newHolesHCP = Array(18).fill(0);
        let hcp = newScoreCard.handicapPlay;
        let holeNumber = 1;
        while (hcp > 0) {
            newHolesHCP[holeNumber - 1]++;
            holeNumber = holeNumber === 18 ? 1 : holeNumber + 1;
            hcp--;
        }
        const otherScoreCard = {
            ...newScoreCard,
            holes: newScoreCard.holes?.map(hole => ({ ...hole, extraShots: newHolesHCP[hole.hcp - 1] }))
        }
        otherScoreCard.stableford = getScoreCardStableford(otherScoreCard);
        return otherScoreCard;
    }


    // ---------------------------------------------------------------

    const addMatch = async matchInitData => {
        const date = Timestamp.fromDate(new Date());
        const theMatch = {
            date: date,
            creationDate: date,
            modifiedDate: date,
            courseId: '',
            tournamentId: '',
            isCompetition: false,
            notes: '',
            scoreCards: [],
            creatorId: appContext.user.uid,
            playMode: playModes[0],
            scoringMode: scoringModes[0],
            userId: appContext.user.uid,
            players: {}
        }
        const newMatch = { ...theMatch, ...matchInitData };

        let newDocRef = ref(getDatabase(), 'matches');
        let newDoc = await push(newDocRef, newMatch)
        newMatch.id = newDoc.key;
        return newMatch
    }

    const updateMatch = async theMatch => {
        const players = theMatch.scoreCards?.reduce((obj, scoreCard) => {
            obj[scoreCard.userId] = 1;
            return obj;
        }, {});

        const newMatch = {
            ...theMatch,
            ...{
                modifiedDate: Timestamp.fromDate(new Date()),
                date: Timestamp.fromDate(theMatch.date),
                players: players || {}
            }
        };
        const newDoc = ref(getDatabase(), 'matches/' + theMatch.id);
        await update(newDoc, newMatch);
    }

    const deleteMatch = async theMatch => {
        const matchDoc = ref(getDatabase(), '/matches/' + theMatch.id);
        await remove(matchDoc)
    }

    // ---------------------------------------------------------------

    const updateCourse = async theCourse => {

        const newCreationDate = theCourse.creationDate || new Date();
        const newCourse = {
            ...theCourse,
            creationDate: newCreationDate,
            lastModifiedDate: new Date(),
        };
        if (!theCourse.id) {
            const theDoc = ref(getDatabase(), 'courses');
            const newDoc = await push(theDoc, newCourse)
            newCourse.id = newDoc.key;
            return newCourse;
        } else {
            const newDoc = ref(getDatabase(), 'courses/' + newCourse.id);
            const ret = await update(newDoc, newCourse);
            return ret;
        }
    }

    const deleteCourse = async theCourse => {
        const scoreCardDoc = ref(getDatabase(), 'courses/' + theCourse.id);
        return await remove(scoreCardDoc);
    }

    // ---------------------------------------------------------------

    const updateUserData = async theUserData => {
        const newDoc = ref(getDatabase(), 'users/' + appContext.user.uid);
        const ret = await update(newDoc, theUserData);
        return ret;
    }

    // ---------------------------------------------------------------

    const addTournament = async tournamentInitData => {
        const date = Timestamp.fromDate(new Date());
        const newTournament = { ...tournamentInitData, creationDate: date, modifiedDate: date };
        let newDocRef = ref(getDatabase(), 'tournaments');
        let newDoc = await push(newDocRef, newTournament);
        newTournament.id = newDoc.key;
        return newTournament;
    }

    const updateTournament = async theTournament => {
        const newDoc = ref(getDatabase(), 'tournaments/' + theTournament.id);
        return await update(newDoc, theTournament);
    }

    const deleteTournament = async theTournament => {
        const newDoc = ref(getDatabase(), 'tournaments/' + theTournament.id);
        return await remove(newDoc);
    }

    // ---------------------------------------------------------------

    const importData = async data => {
        return
    }

    // ---------------------------------------------------------------

    const appContext = useRef({}).current;
    appContext.getScoreCardsPath = () => { return '/scoreCards'; }

    appContext.theme = theme;
    appContext.setTheme = (theTheme) => {

        const metaTag = document.querySelector('meta[name="theme-color"]');
        if (metaTag) {
            metaTag.setAttribute("content", theTheme === "light" ? 'rgb(248, 248, 248)' : 'rgb(63, 62, 62)');
        }
        setTheme(theTheme);
    }

    appContext.user = user;
    appContext.courses = courses;
    appContext.matches = matches;
    appContext.tournaments = tournaments;
    appContext.users = users;
    appContext.userData = userData;
    appContext.logoff = logoff;

    appContext.getCourseById = id => courses?.find(c => c.id === id);
    appContext.getMatchById = id => matches?.find(m => m.id === id);
    appContext.getTournamentById = id => tournaments?.find(t => t.id === id);
    appContext.getUserById = id => users?.find(t => t.id === id);

    appContext.getScoreCardTee = (scoreCard) => {
        const course = appContext.getCourseById(scoreCard.courseId);
        return course ? course.tees.find(tee => (tee.id === scoreCard.teeId) || (tee.name === scoreCard.teeName)) : undefined;
    }

    appContext.addScoreCard = addScoreCard;
    appContext.updateScoreCard = updateScoreCard;
    appContext.deleteScoreCard = deleteScoreCard;

    appContext.addMatch = addMatch;
    appContext.updateMatch = updateMatch;
    appContext.deleteMatch = deleteMatch;

    appContext.updateCourse = updateCourse;
    appContext.deleteCourse = deleteCourse;

    appContext.addTournament = addTournament;
    appContext.updateTournament = updateTournament;
    appContext.deleteTournament = deleteTournament;

    appContext.updateUserData = updateUserData;

    appContext.importData = importData;

    appContext.setHolesByCourseAndTee = setHolesByCourseAndTee;

    // ---------------------------------------------------------------

    appContext.queryUserScoreCards = (userId) => {
        return query(ref(getDatabase(), 'scoreCards'), orderByChild('userId'), equalTo(userId));
    }

    appContext.queryCourseScoreCards = (course) => {
        return query(ref(getDatabase(), 'scoreCards'), orderByChild('courseId'), equalTo(course.id));
    }

    appContext.queryToNextScoreCards = () => {
        const currentSeconds = Math.floor(Date.now() / 1000);
        return query(ref(getDatabase(), 'scoreCards'), orderByChild('date/seconds'), startAfter(currentSeconds));
    }

    appContext.queryScoreCardsTimeRange = (from, end) => {
        const startSeconds = Math.floor(from.getTime() / 1000);
        const endSeconds = Math.floor(end.getTime() / 1000);
        return query(ref(getDatabase(), 'scoreCards'), orderByChild('date/seconds'), startAfter(startSeconds), endBefore(endSeconds));
    }

    appContext.queryScoreCardsFromDate = (from) => {
        const startSeconds = Math.floor(from.getTime() / 1000);
        return query(ref(getDatabase(), 'scoreCards'), orderByChild('date/seconds'), startAfter(startSeconds));
    }

    appContext.queryMatchScoreCards = (match) => {
        return query(ref(getDatabase(), 'scoreCards'), orderByChild('matchId'), equalTo(match.id));
    }

    appContext.queryTournamentScoreCards = (tournament) => {
        return query(ref(getDatabase(), 'scoreCards'), orderByChild('tournamentId'), equalTo(tournament.id));
    }

    appContext.listenToScoreCardsQuery = (query, filterByUserId, callback) => {
        return onValue(query, snapshot => {
            let newScoreCards = getItemsFromDatabaseQuerySnapshot(snapshot);
            if (filterByUserId) {
                newScoreCards = newScoreCards.filter(scoreCard => scoreCard.userId === user.uid);
            }
            newScoreCards.forEach(scoreCard => {
                scoreCard.date = scoreCard.date ? new Date(getTimestampFromDate(scoreCard.date).seconds * 1000) : new Date();
                scoreCard.modifiedDate = scoreCard.modifiedDate ? new Date(getTimestampFromDate(scoreCard.date).seconds * 1000) : new Date();
            })
            if (callback) {
                callback(newScoreCards);
            }
        })
    }

    appContext.listenToScoreCard = (scoreCardId, callback) => {
        const scoreCardQuery = query(ref(getDatabase(), 'scoreCards/' + scoreCardId));
        return onValue(scoreCardQuery, snapshot => {
            const newScoreCard = getItemFromDatabaseSnapshot(snapshot);
            if (newScoreCard) {
                newScoreCard.date = newScoreCard.date ? new Date(getTimestampFromDate(newScoreCard.date).seconds * 1000) : new Date();
                newScoreCard.modifiedDate = newScoreCard.modifiedDate ? new Date(getTimestampFromDate(newScoreCard.date).seconds * 1000) : new Date();
            }
            if (callback) {
                callback(newScoreCard);
            }
        })
    }

    appContext.getScoreCardsByDate = async (date) => {
        const startSeconds = Math.floor(date.getTime() / 1000);
        const theQuery = query(ref(getDatabase(), 'scoreCards'), orderByChild('date/seconds'), startAt(startSeconds), endAt(startSeconds + 86400));
        const snapshot = await get(theQuery);
        let newScoreCards = getItemsFromDatabaseQuerySnapshot(snapshot);
        newScoreCards.forEach(scoreCard => {
            scoreCard.date = scoreCard.date ? new Date(getTimestampFromDate(scoreCard.date).seconds * 1000) : new Date();
            scoreCard.modifiedDate = scoreCard.modifiedDate ? new Date(getTimestampFromDate(scoreCard.date).seconds * 1000) : new Date();
        })

        return newScoreCards;
    }

    // ---------------------------------------------------------------

    appContext.queryMatches = (userId, year) => {
        const dateFrom = new Date(year, 0, 1);
        const dateTo = new Date((year + 1), 0, 1);
        const secondsFrom = dateFrom.getTime() / 1000;
        const secondsTo = dateTo.getTime() / 1000;
        return query(ref(getDatabase(), 'matches'), orderByChild('date/seconds'), startAfter(secondsFrom), endBefore(secondsTo));
    }

    appContext.queryToNextMatches = () => {
        const currentSeconds = Math.floor(Date.now() / 1000);
        return query(ref(getDatabase(), 'matches'), orderByChild('date/seconds'), startAfter(currentSeconds));
    }

    appContext.listenToMatchesQuery = (query, callback) => {
        return onValue(query, snapshot => {
            let newMatches = getItemsFromDatabaseQuerySnapshot(snapshot);
            newMatches.forEach(match => {
                match.date = match.date ? new Date(getTimestampFromDate(match.date).seconds * 1000) : new Date();
                match.modifiedDate = match.modifiedDate ? new Date(getTimestampFromDate(match.date).seconds * 1000) : new Date();
            })
            if (callback) {
                callback(newMatches);
            }
        })
    }

    appContext.listenToMatch = (matchId, callback) => {
        const matchQuery = query(ref(getDatabase(), 'matches/' + matchId));
        return onValue(matchQuery, snapshot => {
            const newMatch = getItemFromDatabaseSnapshot(snapshot);
            if (newMatch) {
                newMatch.date = newMatch.date ? new Date(getTimestampFromDate(newMatch.date).seconds * 1000) : new Date();
                newMatch.modifiedDate = newMatch.modifiedDate ? new Date(getTimestampFromDate(newMatch.date).seconds * 1000) : new Date();
            }
            if (callback) {
                callback(newMatch);
            }
        })
    }

    // ---------------------------------------------------------------

    appContext.listenToTournament = (tournamentId, callback) => {
        const matchQuery = query(ref(getDatabase(), 'tournaments/' + tournamentId));
        return onValue(matchQuery, snapshot => {
            const newTournament = getItemFromDatabaseSnapshot(snapshot);
            if (newTournament) {
                newTournament.date = newTournament.date ? new Date(getTimestampFromDate(newTournament.date).seconds * 1000) : new Date();
                newTournament.modifiedDate = newTournament.modifiedDate ? new Date(getTimestampFromDate(newTournament.date).seconds * 1000) : new Date();
            }
            if (callback) {
                callback(newTournament);
            }
        })
    }

    // ---------------------------------------------------------------

    getAuth().onAuthStateChanged(newUser => {
        if (user !== newUser) {
            setUser(newUser);
        }
    });

    // ---------------------------------------------------------------

    useEffect(() => {
        if (!user) {
            setCourses();
            setMatches();
            setTournaments();
            setUsers()
            setUserData();
            return;
        }

        appContext.setTheme(appContext.theme);
        /*
                const scoreCardsQuery = query(ref(getDatabase(), 'scoreCards'), orderByChild('userId'), equalTo(user.uid));
                onValue(scoreCardsQuery, snapshot => {
                    const newScoreCards = getItemsFromDatabaseQuerySnapshot(snapshot);
                    newScoreCards.forEach(scoreCard => {
                        scoreCard.date = scoreCard.date ? new Date(getTimestampFromDate(scoreCard.date).seconds * 1000) : new Date();
                        scoreCard.modifiedDate = scoreCard.modifiedDate ? new Date(getTimestampFromDate(scoreCard.date).seconds * 1000) : new Date();
                    })
                    const sortedScoreCards = newScoreCards.sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0);
                    setScoreCards(sortedScoreCards);
                })
        
                const scoreCardsInMatchesQuery = query(ref(getDatabase(), 'scoreCards'), orderByChild('matchId'), startAfter(""));
                onValue(scoreCardsInMatchesQuery, snapshot => {
                    const newScoreCards = getItemsFromDatabaseQuerySnapshot(snapshot)
                    newScoreCards.forEach(scoreCard => {
                        scoreCard.date = scoreCard.date ? new Date(getTimestampFromDate(scoreCard.date).seconds * 1000) : new Date();
                        scoreCard.modifiedDate = scoreCard.modifiedDate ? new Date(getTimestampFromDate(scoreCard.date).seconds * 1000) : new Date();
                    })
                    const sortedScoreCards = newScoreCards.sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0);
                    setScoreCardsInMatches(sortedScoreCards);
                })
        */
        const coursesQuery = query(ref(getDatabase(), 'courses'));
        onValue(coursesQuery, snapshot => {
            const newCourses = getItemsFromDatabaseQuerySnapshot(snapshot)
            newCourses.forEach(course => {
                course.creationDate = course.creationDate ? new Date(getTimestampFromDate(course.creationDate).seconds * 1000) : new Date();
                course.lastModifiedDate = course.lastModifiedDate ? new Date(getTimestampFromDate(course.lastModifiedDate).seconds * 1000) : new Date();
            })
            setCourses(newCourses.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
        })

        /*
        const matchesQuery = query(ref(getDatabase(), 'matches'));
        onValue(matchesQuery, snapshot => {
            let newMatches = getItemsFromDatabaseQuerySnapshot(snapshot)
            //            newMatches = newMatches.filter(match => match.creatorId === user.uid || (match.players && match.players.includes(user.uid)))
            newMatches.forEach(match => {
                match.date = new Date(getTimestampFromDate(match.date).seconds * 1000);
            })
            const sortedMatches = newMatches.sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0);
            setMatches(sortedMatches);
        })
        */

        const usersQuery = query(ref(getDatabase(), 'users'));
        onValue(usersQuery, snapshot => {
            const newUsers = getItemsFromDatabaseQuerySnapshot(snapshot)
            setUsers(newUsers);

            const userData = newUsers.find(u => u.id === user.uid);
            setUserData({
                ...userData,
                id: userData.id,
                firstName: userData.firstName,
                lastName: userData.lastName,
                handicap: userData.handicap || 36,
                handicapPitchAndPutt: userData.handicapPitchAndPutt || 36,
                email: userData.email
            });
        })

        const tournamentsQuery = query(ref(getDatabase(), 'tournaments'));
        onValue(tournamentsQuery, snapshot => {
            const newTournaments = getItemsFromDatabaseQuerySnapshot(snapshot);
            const filteredTournaments = newTournaments.filter(tournament => tournament.players?.find(player => player.userId === user.uid) || user.uid === tournament.organizer?.id);
            const sortedTournaments = filteredTournaments.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
            setTournaments(sortedTournaments);
        })

        return () => {
            //            off(scoreCardsQuery);
            //            off(scoreCardsInMatchesQuery);
            off(coursesQuery);
            // off(matchesQuery);
            off(tournamentsQuery);
            off(usersQuery);
        }
    }, [user]);

    // ---------------------------------------------------------------

    return (
        <AppContext.Provider value={{ appContext }}>
            {children}
        </AppContext.Provider>
    )
}
